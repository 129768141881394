import React, { useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  Form, Input, Modal, Radio, Select, Space, Spin, Tag, Tooltip, Checkbox,
} from 'antd';
import StarRatings from 'react-star-ratings';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { parse } from 'query-string';
import {
  getCenterTypeDataAction, getChildCenterDataAction, getCountryDataAction, getStateDataAction, getStateDataResetAction,
} from './logic';
import { getCookie, hasPermissionForAction } from '../../../utils';
import { getautosuggestionCancelAction, getautosuggestionResetAction, getautosuggestvalueAction } from '../AddProjectName/logic';
import PiAutoSelect from '../AutoSuggest/PiAutoSelect';
import { simsIdPresentAction, simsIdPresentResetAction } from '../../components/AddInvestigator/logic';
import Loader from '../../../common/components/Loader';
import PiAutoSelectForParentInstitution from '../AutoSuggest/PiAutoSelectForParentInstitution';

const regex = /^(?:[0-9]{10},)*[0-9]{10}$/

const { Search } = Input

const GeneralInformation = ({ data }) => {
  const siteQuesResponse = useSelector((store) => store.siteQuesResponse);
  const getStateData = useSelector((store) => store.getStateData);
  const centerTypeDataResponse = useSelector((store) => store.centerTypeDataResponse);
  const childCenterData = useSelector((store) => store.getChildCenterDataResponse)
  const getCountryData = useSelector((store) => store.getCountryData);
  const [childCenterOpenModal, setChildCenterOpenModal] = useState(false)
  const [childCentersList, setChildCentersList] = useState([])
  const [noAllianceChildCenters, setNoAllianceChildCenters] = useState([])
  const [center, setCenter] = useState({ val: data?.generalInformation?.alliance_member, key: data?.generalInformation?.alliance_member_id, alliance_type: data?.generalInformation?.alliance_type });
  const [newId, setNewId] = useState({
    key: '',
    type: '',
    value: '',
    label: '',
  })
  const [editId, setEditId] = useState({
    key: '',
    type: '',
    value: '',
    label: '',
  })
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    editKey: '',
  })
  const tagTitle = {
    tier: 'Tier',
    compliance: 'Compliance',
    alliance: 'Alliance type',
    indications: 'Indications',
    total_number_of_popis: 'Total PIs',
    industry_studies: 'Industry studies',
    interventional_studies: 'Interventional studies',
    observational_studies: 'Observational studies',
    parexel_studies: 'Parexel studies',
    enrollment_subjects: 'Enrolled subjects',
    department: 'Department',
  };

  const [deleteIdConfirmationModal, setDeleteIdConfirmationModal] = useState({ isOpen: false, obj: {} })
  const [idExists, setIdExists] = useState(false)
  const [naNError, setNaNError] = useState(false)
  const [cnnExists, setCnnExists] = useState(false)
  const [validationError, setValidationError] = useState(false)
  const idsList = [
    {
      value: 'ccn_number',
      label: 'CCN Number',
    },
    {
      value: 'npi',
      label: 'NPI Id',
    },
    {
      value: 'pac_ids',
      label: 'PAC Id',
    },
    {
      value: 'taxation_ids',
      label: 'Taxation id',
    },
  ]
  const isSimsIdPresent = useSelector((store) => store.isSimsIdPresent)
  const [simsIdError, setSimsIdError] = useState(false)
  const [simsIdAvailable, setSimsIdAvailable] = useState(false)

  const [veevaReadyWarningModal, setVeevaReadyWarningModal] = useState(false)
  const [veevaReadyNoValueWarningModal, setVeevaReadyNoValueWarningModal] = useState(false)
  const [veevaReadyValue, setveevaReadyValue] = useState(data?.generalInformation?.veeva_ready_flag_updated?.value)

  useEffect(() => {
    if (data.flag === 'editCenter' && parse(location.search).currentTab !== 'parent_centers') {
      setCenter({ val: data.generalInformation?.alliance_member?.value, key: data.generalInformation?.alliance_member_id?.value, alliance_type: data.generalInformation?.alliance_type?.value })
    } else if (parse(location.search).currentTab !== 'parent_centers' && (data.flag === 'fromMainCenter' || data.flag === 'savedAnalysis')) {
      setCenter({ val: data.generalInformation?.alliance_member, key: data.generalInformation?.alliance_member_id, alliance_type: data.generalInformation?.alliance_type })
    } else if (data.flag === 'editCenter' && parse(location.search).currentTab === 'parent_centers') {
      setCenter({ alliance_type: data.generalInformation?.alliance_type?.value })
    } else {
      setCenter({ alliance_type: data.generalInformation?.alliance_type })
    }
  }, [JSON.stringify(data.flag)])

  useEffect(() => {
    setveevaReadyValue(data?.generalInformation?.veeva_ready_flag_updated?.value)
  }, [data?.generalInformation])

  useEffect(() => {
    if (parse(location.search).currentTab !== 'parent_centers') {
      if (data.flag === 'fromMainCenter' || data.flag === 'savedAnalysis') {
        if (data?.generalInformation?.solo_center_flag === false && data?.generalInformation?.alliance_member_id === undefined) {
          data.setNoParent(true)
        } else if (data?.generalInformation?.solo_center_flag === false && data?.generalInformation?.alliance_member_id !== '') {
          data.setNoParent(false)
        } else if (data?.generalInformation?.solo_center_flag === false && data?.generalInformation?.alliance_member_id === '') {
          data.setNoParent(true)
        } else if (data?.generalInformation?.solo_center_flag === true && data?.generalInformation?.alliance_member_id === undefined) {
          data.setNoParent(false)
        } else if (data?.generalInformation?.solo_center_flag === true && data?.generalInformation?.alliance_member_id === '') {
          data.setNoParent(false)
        } else if (data?.generalInformation?.solo_center_flag === true && data?.generalInformation?.alliance_member_id !== '') {
          data.setNoParent(false)
        }
      } else if (data.flag === 'editCenter') {
        if (data?.generalInformation?.alliance_member_id?.value !== '' && data?.generalInformation?.solo_center_flag?.value === false) {
          data.setNoParent(false)
        } else if (data?.generalInformation?.alliance_member_id?.value === '' && data?.generalInformation?.solo_center_flag?.value === true) {
          data.setNoParent(false)
        } else if (data?.generalInformation?.solo_center_flag?.value === false && data?.generalInformation?.alliance_member_id.value === '') {
          data.setNoParent(true)
        }
      }
    }
  }, [data.generalInformation.solo_center_flag, data])

  useEffect(() => {
    if (isSimsIdPresent.flag) {
      if (isSimsIdPresent?.data?.isPresent) {
        setSimsIdAvailable(false)
        setSimsIdError(true)
        data.setIsSimsIdVerified(false)
      } else {
        setSimsIdError(false)
        setSimsIdAvailable(true)
        data.setIsSimsIdVerified(true)
      }
      dispatch(simsIdPresentResetAction({}))
    }
  }, [JSON.stringify(isSimsIdPresent)])

  useEffect(() => {
    if (getStateData.flag) {
      data.setStateFlag(getStateData.data.is_state)
    }
  }, [getStateData.flag])

  useEffect(() => {
    if (Object.keys(center).length) {
      if (data.flag === 'fromMainCenter' || data.flag === 'savedAnalysis') {
        if (data?.generalInformation?.alliance_member_id?.key !== center.key) {
          data.setGeneralInformation((oldState) => {
            const temp = {}
            temp.alliance_member = center.val
            temp.alliance_member_id = center.key
            temp.alliance_type = center?.alliance_type === '' ? '' : center.alliance_type === 'AM' ? 'AM' : undefined
            return { ...oldState, ...temp }
          })
        }
      } else if (center?.alliance_type !== undefined) {
        data.setGeneralInformation((oldState) => {
          const temp = {}
          temp.alliance_member = { value: center.val !== undefined ? center.val : '', flag: true }
          temp.alliance_member_id = { value: center.key !== undefined ? center.key : '', flag: true }
          temp.alliance_type = { value: center?.alliance_type === '' ? '' : center?.alliance_type !== '' ? center?.alliance_type : undefined, flag: true }
          return { ...oldState, ...temp }
        })
      } else if (!center?.alliance_type) {
        data.setGeneralInformation((oldState) => {
          const temp = {}
          temp.alliance_member = { value: center.val !== undefined ? center.val : '', flag: true }
          temp.alliance_member_id = { value: center.key !== undefined ? center.key : '', flag: true }
          temp.alliance_type = { value: undefined, flag: true }
          return { ...oldState, ...temp }
        })
      }
    }
  }, [JSON.stringify(center)])

  useEffect(() => {
    dispatch(getStateDataAction({ country: data?.generalInformation.country?.value }))
    if (parse(location.search).currentTab === 'parent_centers' && parse(location.search).id) {
      dispatch(getChildCenterDataAction({ id: parse(location.search).id, fields: 'id,name,alliance_type,address,landscape_id,tier,indications' }))
    }
  }, [])

  useEffect(() => {
    if (childCenterData.flag) {
      setChildCentersList(childCenterData?.data?.data)
      const temp = childCenterData?.data?.data.filter((site) => site.alliance_type === '')
      setNoAllianceChildCenters(temp.map((itm) => itm.id))
    }
  }, [JSON.stringify(childCenterData)])

  const [centerTypeData, setCenterTypeData] = useState([])
  const [countryData, setCountryData] = useState([])

  useEffect(() => {
    dispatch(getCenterTypeDataAction({
      filter_key: 'center_type',
      type: parse(location.search).currentTab === 'parent_centers' ? 'parent' : 'center',
    }))
    dispatch(getCountryDataAction())
  }, [])

  useEffect(() => {
    if (centerTypeDataResponse.flag) {
      setCenterTypeData(centerTypeDataResponse?.data?.center_type)
    }
    if (getCountryData.flag) {
      setCountryData(getCountryData?.data?.distinct_countries)
    }
  }, [JSON.stringify(centerTypeDataResponse), JSON.stringify(getCountryData)])

  useEffect(() => {
    if (data.flag === 'fromMainCenter' && parse(location.search).currentTab !== 'parent_centers') {
      data.setCountryForNpiNumber(data.generalInformation.country)
      if (data.generalInformation.country === 'United States') {
        data.setWarningFlag(true)
      } else {
        data.setWarningFlag(false)
      }
    } else if (data.flag === 'editCenter' && parse(location.search).currentTab !== 'parent_centers') {
      data.setCountryForNpiNumber(data.generalInformation.country.value)
      if (data.generalInformation.country.value === 'United States') {
        data.setWarningFlag(true)
      } else {
        data.setWarningFlag(false)
      }
    }
  }, [data.generalInformation.country])

  const { Option } = Select;
  const dispatch = useDispatch();

  const getValue = (keyName) => {
    if (data.flag === 'fromMainCenter') {
      if (keyName === 'alliance_type' && data?.generalInformation[keyName] !== '' && data?.generalInformation[keyName] !== undefined) {
        return 'AM'
      }
      return data?.generalInformation[keyName];
    }
    if (data?.generalInformation && data?.generalInformation[keyName]) {
      if (keyName === 'alliance_type' && data?.generalInformation[keyName]?.value !== '' && data?.generalInformation[keyName]?.value !== undefined) {
        return 'AM'
      }
      return data?.generalInformation[keyName]?.value;
    }
    return '';
  };

  const handleSimsIdChange = (e) => {
    setSimsIdError(false)
    setSimsIdAvailable(false)
    if (e === '') {
      data.setIsSimsIdVerified(true)
    } else {
      data.setIsSimsIdVerified(false)
    }
    if (isNaN(e)) {
      setNaNError(true)
      data.setIsSimsIdVerified(false)
    } else {
      setNaNError(false)
      data.setSimsId(e)
    }
  }

  // const isAllianceDisabled = () => {
  //   if (data.flag === 'editCenter') {
  //     if (data?.generalInformation?.solo_center_flag?.value) {
  //       return true
  //     }
  //     if (center?.alliance_type === '') {
  //       return true
  //     }
  //     return false
  //   }

  //   if (center?.alliance_type === undefined) {
  //     if (getValue('alliance_member_alliance_type') !== '') {
  //       return false
  //     }
  //     return true
  //   }
  //   if (data?.GeneralInformation?.solo_center_flag) {
  //     return true
  //   }
  //   if (center?.alliance_type === '') {
  //     return true
  //   }
  //   return false
  // }

  const handleAllianceModalDoneClicked = () => {
    data.setGeneralInformation((oldState) => {
      const temp = {};
      temp.alliance_type = { ...oldState.alliance_type, except_center_ids: noAllianceChildCenters, flag: true };
      return { ...oldState, ...temp };
    })
    setChildCenterOpenModal(false)
  }
  const onCenterStateChange = (e, keyName, type) => {
    if (data.flag === 'fromMainCenter') {
      if (keyName === 'center_type') {
        data.setGeneralInformation((oldState) => {
          const temp = {};
          temp[keyName] = e;
          return { ...oldState, ...temp };
        });
      } else if (keyName === 'country') {
        dispatch(getStateDataAction({ country: e }))
        dispatch(getStateDataResetAction())
        data.setGeneralInformation((oldState) => {
          return { ...oldState, country: e, state: '' };
        });
      } else {
        const value = keyName === 'state' || keyName === 'alliance_type' || keyName === 'pxl_manager' || keyName === 'site_manager' ? e : e.target.value;
        data.setGeneralInformation((oldState) => {
          const temp = {};
          temp[keyName] = value;
          return { ...oldState, ...temp };
        });
      }
    } else if (keyName === 'center_type') {
      data.setGeneralInformation((oldState) => {
        const temp = {};
        temp[keyName] = { value: e, flag: true }
        return { ...oldState, ...temp };
      });
    } else if (keyName === 'country') {
      dispatch(getStateDataAction({ country: e }))
      dispatch(getStateDataResetAction())
      data.setGeneralInformation((oldState) => {
        return { ...oldState, country: { value: e, flag: true }, state: { value: '', flag: true } };
      });
    } else if (type !== undefined) {
      let valueVariable = ''
      if (keyName === 'alliance_member_name') {
        valueVariable = e.target.value
      } else {
        valueVariable = e?.target?.value || e;
      }
      data.setGeneralInformation((oldState) => {
        const temp = {};
        temp[keyName] = { value: valueVariable, flag: true };
        return { ...oldState, ...temp };
      });
    } else {
      const valueVariable = keyName === 'state' || keyName === 'pxl_manager' || keyName === 'site_manager' || keyName === 'alliance_type' || keyName === 'alliance_member_name' || keyName === 'is_pxl_recomd_partnering' ? e : e.target.value;
      data.setGeneralInformation((oldState) => {
        const temp = {};
        temp[keyName] = { value: valueVariable, flag: true };
        return { ...oldState, ...temp };
      });
    }
  };

  const onStateChange = (e, keyName, flag) => {
    if (data.flag === 'fromMainCenter') {
      if (flag === 'api') {
        const value = e
        data.setGeneralInformation((oldState) => {
          const temp = {};
          temp[keyName] = value;
          return { ...oldState, ...temp };
        });
      } else {
        const { value } = e.target
        data.setGeneralInformation((oldState) => {
          const temp = {};
          temp[keyName] = value;
          return { ...oldState, ...temp };
        });
      }
    } else if (flag === 'api') {
      const value = e
      data.setGeneralInformation((oldState) => {
        const temp = {};
        temp[keyName] = { value, flag: true };
        return { ...oldState, ...temp };
      });
    } else {
      const { value } = e.target
      data.setGeneralInformation((oldState) => {
        const temp = {};
        temp[keyName] = { value, flag: true };
        return { ...oldState, ...temp };
      });
    }
  }

  const onDateChange = (date, dateString, keyName) => {
    if (data.flag === 'fromMainCenter') {
      data.setGeneralInformation((oldState) => {
        const temp = {};
        temp[keyName] = dateString;
        return { ...oldState, ...temp };
      });
    } else {
      data.setGeneralInformation((oldState) => {
        const temp = {};
        temp[keyName] = { value: dateString, flag: true };
        return { ...oldState, ...temp };
      });
    }
  };

  const getQuesAns = (keyName) => {
    if (data.generalInformation[keyName]?.value) {
      return data.generalInformation[keyName].value;
    }
    return data.generalInformation[keyName];
  };

  const showLabelOptions = (itm) => {
    const { child_queston } = itm;
    if (child_queston.length) {
      return child_queston?.map((child) => {
        const itemChild = Object.keys(siteQuesResponse?.data?.data)
          .map((res) => siteQuesResponse.data.data[res])
          .find((o) => o.id === child);
        return renderQuesAns(itemChild);
      });
    }
    return '';
  };

  const showOptions = (itm) => {
    const { options } = itm;
    if (options.length) {
      return options.map((opt) => {
        if (getValue(itm.id) === opt.option_text) {
          const childQue = opt.child_questions;
          return childQue?.map((child) => {
            const itemChild = Object.keys(siteQuesResponse?.data?.data)
              .map((res) => siteQuesResponse.data.data[res])
              .find((o) => o.id === child);
            return renderQuesAns(itemChild);
          });
        }
        return '';
      });
    }
    return '';
  };

  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1
    }
    if (value === 'Class 2') {
      return 2
    }
    if (value === 'Class 1') {
      return 3
    }
    return 0
  }

  const handleCheckboxClick = (id) => {
    if (noAllianceChildCenters.includes(id)) {
      setNoAllianceChildCenters(noAllianceChildCenters.filter((itm) => itm !== id))
    } else {
      setNoAllianceChildCenters([...noAllianceChildCenters, id])
    }
  }
  const renderQuesAns = (itm) => {
    return (
      <Form layout="vertical" form={data.generalInformationForm}>
        <div className="head-wrapper">
          <div className="inner-card">
            <div className="question-card">
              <Form.Item label={itm.question_text} className="question-wrapper">
                {itm.type === 'single_select' ? (
                  <>
                    <Radio.Group
                      onChange={(e) => onCenterStateChange(e, itm.id, itm.type)}
                      value={getQuesAns(itm.id)}
                    >
                      <Space direction="vertical">
                        {itm.options.map((j) => {
                          return (
                            <Radio value={j.option_text}>{j.option_text}</Radio>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                    {showOptions(itm)}
                  </>
                ) : itm.type === 'text' ? (
                  <Input
                    className="input-wrapper"
                    placeholder="Please enter"
                    value={getQuesAns(itm.id)}
                    onChange={(e) => onCenterStateChange(e, itm.id, itm.type)}
                  />
                ) : itm.type === 'file_upload' ? (
                  <Button type="primary" disabled>
                    Upload here
                  </Button>
                ) : itm.type === 'date' ? (
                  <div className="date-wrap">
                    <DatePicker
                      onChange={(date, dateString) => onDateChange(date, dateString, itm.id)}
                      value={moment(getQuesAns(itm.id), 'YYYY-MM-DD')}
                      format={dateFormat}
                      placeholder={dateFormat}
                    />
                  </div>
                ) : itm.type === 'label' ? (
                  showLabelOptions(itm)
                ) : (
                  ''
                )}
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    );
  };

  const dateFormat = 'YYYY-MM-DD';
  const onCenterChangeInput = (e) => {
    if (e instanceof Object && 'val' in e) {
      if (e.val.trim() && e.val.length > 2) {
        dispatch(
          getautosuggestvalueAction({
            query: e.val.trim(),
            type: 'sites',
            filters: JSON.stringify(
              {
                parent_flag: true,
              },
            ),
          }),
        )
      }
    } else if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'sites',
          filters: JSON.stringify(
            {
              parent_flag: true,
            },
          ),
        }),
      )
    }
  }

  const AddNewIdClicked = () => {
    const alreadyExist = data.ids.filter((id) => id.type === newId.type && id.value === newId.value)
    const cnnIdExists = data.ids.filter((id) => id.type === 'ccn_number')
    if (cnnIdExists.length && newId.type === 'ccn_number') {
      setCnnExists(true)
    } else if (alreadyExist.length) {
      setIdExists(true)
    } else {
      setIdExists(false)
      setCnnExists(false)
      const temp = newId
      setNewId({
        key: '',
        type: '',
        value: '',
        label: '',
      })
      data.setIds([...data.ids, { ...temp, key: new Date().getTime() }]);
    }
  }

  const handleDeleteIdClick = (itm) => {
    data.setIds(data.ids.filter((item) => item.key !== itm.key))
    setDeleteIdConfirmationModal({ isOpen: false, obj: {} })
  }

  const handleEditIdClick = (itm) => {
    setIsEdit({ isOpen: true, editKey: itm.key })
    setEditId({
      key: itm.key,
      type: itm.type,
      value: itm.value,
      label: itm.label,
    })
  }

  const onEditCancelClick = () => {
    setIsEdit({
      isOpen: false,
      editKey: '',
    })
  }

  const onEditSaveClicked = () => {
    const index = data.ids.findIndex((itm) => itm.key === isEdit.editKey)
    const temp = data.ids
    temp[index] = editId
    data.setIds(temp)
    setIsEdit({ isOpen: false, editKey: '' })
  }

  const onChangeIds = (e) => {
    if (newId.type === 'npi') {
      if (!regex.test(e.target.value)) {
        setValidationError(true)
        setNewId({ ...newId, value: e.target.value })
      } else {
        setValidationError(false)
        setIdExists(false);
        setCnnExists(false);
        setNewId({ ...newId, value: e.target.value })
      }
    } else {
      setIdExists(false);
      setCnnExists(false);
      setNewId({ ...newId, value: e.target.value })
    }
  }
  const onNoParentChange = (value) => {
    if (data.flag === 'fromMainCenter' || data.flag === 'savedAnalysis') {
      if (value.target && value.target.checked) {
        data.setNoParent(true)
        data.setGeneralInformation((oldState) => {
          const temp = {}
          temp.alliance_member = ''
          temp.alliance_member_id = ''
          temp.alliance_type = undefined
          temp.solo_center_flag = true

          return { ...oldState, ...temp }
        })
      } else {
        data.setNoParent(false)
        data.setGeneralInformation((oldState) => {
          const temp = {}
          temp.alliance_member = ''
          temp.alliance_member_id = ''
          temp.alliance_type = undefined
          temp.solo_center_flag = false

          return { ...oldState, ...temp }
        })
      }
    } else if (data.flag === 'editCenter') {
      if (value.target && value.target.checked) {
        data.setNoParent(true)
        data.setGeneralInformation((oldState) => {
          const temp = {}
          temp.alliance_member = { value: '', flag: true }
          temp.alliance_member_id = { value: '', flag: true }
          temp.alliance_type = { value: undefined, flag: true }
          temp.solo_center_flag = { value: true, flag: true }

          return { ...oldState, ...temp }
        })
      } else {
        data.setNoParent(false)
        data.setGeneralInformation((oldState) => {
          const temp = {}
          temp.alliance_member = { value: '', flag: true }
          temp.alliance_member_id = { value: '', flag: true }
          temp.alliance_type = { value: undefined, flag: true }
          temp.solo_center_flag = { value: false, flag: true }

          return { ...oldState, ...temp }
        })
      }
    }
  }

  const onRadioValueChange = (v) => {
    setveevaReadyValue(v.target.value)
    if (v.target.value === 'Yes' && getValue('veeva_ready_flag_updated') === 'Pending review') {
      setVeevaReadyWarningModal(true)
    } else if (v.target.value === 'No' && getValue('veeva_ready_flag_updated') === 'Pending review') {
      setVeevaReadyNoValueWarningModal(true)
    } else if (v.target.value === 'Yes' && getValue('veeva_ready_flag_updated') === 'No') {
      setVeevaReadyWarningModal(true)
    } else if (v.target.value === 'Pending review' && getValue('veeva_ready_flag_updated') === 'No') {
      if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
        data.setGeneralInformation((oldState) => {
          const temp = {};
          temp.veeva_ready_flag_updated = 'Pending review'
          return { ...oldState, ...temp };
        });
      } else {
        data.setGeneralInformation((oldState) => {
          const temp = {};
          temp.veeva_ready_flag_updated = { value: 'Pending review', flag: true }
          return { ...oldState, ...temp };
        });
      }
    }
  }

  const submitVeevaReadyValue = () => {
    if (data.flag === 'fromMainPage' || data.flag === 'savedAnalysis') {
      data.setGeneralInformation((oldState) => {
        const temp = {};
        temp.veeva_ready_flag_updated = 'Yes'
        return { ...oldState, ...temp };
      });
    } else {
      data.setGeneralInformation((oldState) => {
        const temp = {};
        temp.veeva_ready_flag_updated = { value: 'Yes', flag: true }
        return { ...oldState, ...temp };
      });
    }
    setVeevaReadyWarningModal(false)
  }

  const checkedMandatoryFields = () => {
    if (parse(location.search).currentTab !== 'parent_centers') {
      if (getValue('centerName') && getValue('address_line1') && getValue('city') && getValue('postalcode') && getValue('state')
      && getValue('centerName') !== '' && getValue('centerName').length >= 3
    && getValue('address_line1') !== '' && getValue('address_line1').length >= 3
    && getValue('city') !== '' && getValue('city').length >= 3
    && getValue('postalcode') !== '' && getValue('postalcode').length >= 3
    && getValue('country') !== ''
    && getValue('state') !== '') {
        return true
      }
      return false
    }
    if (getValue('centerName') && getValue('centerName') !== '' && getValue('centerName').length >= 3) {
      return true
    }
    return false
  }

  return (
    <div className="investigator-page-wrapper-in">
      <div className={getCookie('permissions')?.includes('super_admin') && data.flag === 'admin' ? 'page-wrapper-in-section disable-pi' : 'page-wrapper-in-section'}>
        <Form layout="vertical" name="generalform1" form={data.generalInformationForm} className={getCookie('permissions')?.includes('super_admin') && data.flag === 'admin' ? 'disable-pi-form' : null}>
          <div className="head-wrapper">
            <div className="inner-card">
              <div className="name-card">
                <Form.Item
                  label={parse(location.search).currentTab === 'parent_centers' ? 'Name of Parent Institute' : 'Name of Center'}
                  // label="Name of Center"
                  name="name"
                  rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: parse(location.search).currentTab === 'parent_centers' ? 'Parent institution name should be more than 3 characters' : 'Center name should be more than 3 characters' }, { type: 'string', max: 200, message: parse(location.search).currentTab === 'parent_centers' ? 'Parent institution name should be less than 200 characters' : 'Center name should be less than 200 characters' }]}
                  className="name-wrapper"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter name"
                    value={getValue('centerName')}
                    defaultValue={getValue('centerName')}
                    onChange={(e) => onCenterStateChange(e, 'centerName')}
                  />
                </Form.Item>
              </div>

              <div className="telephone-card">
                <Form.Item
                  label="Primary Phone Number"
                  name="primary_phone"
                  rules={[{ required: true, message: 'This is a required field' }, { pattern: /^\d+$/, minlength: 5, message: 'No characters allowed or enter valid number' }]}
                  className="telephone-wrapper"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter phone number"
                    value={getValue('primary_phone')}
                    defaultValue={getValue('primary_phone')}
                    onChange={(e) => onCenterStateChange(e, 'primary_phone')}
                  />
                </Form.Item>
              </div>

              <div className="telephone-card">
                <Form.Item
                  label="Telephone"
                  name="telephone"
                  rules={[{ pattern: /^[\d\s\-\\,\\+]+$/, minlength: 5, message: 'No characters allowed or enter valid number' }]}
                  className="telephone-wrapper"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter telephone number"
                    value={getValue('telephoneNo')}
                    defaultValue={getValue('telephoneNo')}
                    onChange={(e) => onCenterStateChange(e, 'telephoneNo')}
                  />
                </Form.Item>
              </div>

              <div className="mobile-card">
                <Form.Item
                  label="Mobile number"
                  name="mobile"
                  rules={[{
                    pattern: /^[0-9\\,\-\\+ ]+$/, message: 'Enter a valid phone no.',
                  }]}
                  className="mobile-wrapper "
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter mobile number"
                    value={getValue('mobileNo')}
                    defaultValue={getValue('mobileNo')}
                    onChange={(e) => onCenterStateChange(e, 'mobileNo')}
                  />
                </Form.Item>
              </div>
              <div className="mobile-card">
                <Form.Item
                  label="Fax Number"
                  name="fax"
                  rules={[{
                    pattern: /^[0-9\\,\-\\+ ]+$/, message: 'Enter a valid fax no.',
                  }]}
                  className="mobile-wrapper "
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter fax number"
                    value={getValue('fax')}
                    defaultValue={getValue('fax')}
                    onChange={(e) => onCenterStateChange(e, 'fax')}
                  />
                </Form.Item>
              </div>

              <div className="mobile-card">
                <Form.Item
                  label="URL"
                  name="url"
                  rules={[
                    {
                      pattern: new RegExp('^(https?:\\/\\/)?((([-a-z0-9]{1,63}\\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\\.[a-z]{2,63})|((\\d{1,3}\\.){3}\\d{1,3}))(:\\d{1,5})?((\\/|\\?)((%[0-9a-f]{2})|[-\\w\\+\\.\\?\\/@~#&=])*)?$'),
                      message: 'Enter valid Url',
                    },
                  ]}
                  className="mobile-wrapper "
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter Url"
                    value={getValue('url')}
                    defaultValue={getValue('url')}
                    onChange={(e) => onCenterStateChange(e, 'url')}
                  />
                </Form.Item>
              </div>

              <div className="email-card">
                <Form.Item
                  label="Primary Email"
                  name="email"
                  rules={[{ pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Enter a valid email' }]}
                  className="email-wrapper "
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter email"
                    value={getValue('email')}
                    defaultValue={getValue('email')}
                    onChange={(e) => onCenterStateChange(e, 'email')}
                  />
                </Form.Item>
              </div>

              <div className="center-type-card">
                <Form.Item
                  label={parse(location.search).currentTab !== 'parent_centers' ? 'Center Type' : 'Parent Institution Type'}
                  name="centerType"
                  rules={[{ required: true, message: 'This is a required field' }]}
                  className="center-type-wrapper "
                >
                  <Select
                    showSearch
                    placeholder={parse(location.search).currentTab !== 'parent_centers' ? 'Select center type' : 'Select parent institution type'}
                    style={{
                      width: 120,
                    }}
                    value={getValue('center_type')}
                    defaultValue={getValue('center_type')}
                    onChange={(e) => onCenterStateChange(e, 'center_type')}
                  >
                    {centerTypeData && centerTypeData?.map((itm) => {
                      return <Option value={itm}>{itm}</Option>
                    })}
                  </Select>
                </Form.Item>

              </div>

              <div className="email-card">
                <Form.Item
                  label="Department"
                  name="department"
                  className="email-wrapper "
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter Department"
                    value={getValue('department')}
                    defaultValue={getValue('department')}
                    onChange={(e) => onCenterStateChange(e, 'department')}
                  />
                </Form.Item>
              </div>
              <div className="email-card">
                <Form.Item
                  label="OMS Organization ID"
                  name="oms_org_id"
                  className="email-wrapper "
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter OMS organization ID"
                    value={getValue('oms_org_id')}
                    defaultValue={getValue('oms_org_id')}
                    onChange={(e) => onCenterStateChange(e, 'oms_org_id')}
                  />
                </Form.Item>
              </div>
              <div className="email-card">
                <Form.Item
                  label="OMS Location ID"
                  name="oms_loc_id"
                  className="email-wrapper "
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter OMS location ID"
                    value={getValue('oms_loc_id')}
                    defaultValue={getValue('oms_loc_id')}
                    onChange={(e) => onCenterStateChange(e, 'oms_loc_id')}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
        {parse(location.search).currentTab !== 'parent_centers' && (
          <Form layout="vertical" name="generalform1" className={getCookie('permissions')?.includes('super_admin') && data.flag === 'admin' ? 'disable-pi-form' : null}>
            <div className="head-wrapper">
              <div className="inner-card">
                <div className="email-card">
                  <Form.Item
                    label="Solo Center ?"
                    // label="Alliance Member Name"
                    className="email-wrapper "
                  >
                    <Checkbox onChange={onNoParentChange} checked={data.flag === 'editCenter' ? data?.generalInformation?.solo_center_flag?.value : data?.generalInformation?.solo_center_flag}>Solo Center / No Parent</Checkbox>
                  </Form.Item>
                </div>
                {
                  (data.flag === 'editCenter' ? !data?.generalInformation?.solo_center_flag?.value : !data?.generalInformation?.solo_center_flag) ? (
                    <div className="name-card">
                      <Form.Item
                        label="Parent Institution"
                      // label="Alliance Member Name"
                        name="alliance_member_id"
                        className="name-wrapper primary-center-name"
                        rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                      >
                        <PiAutoSelectForParentInstitution
                          apiCall={onCenterChangeInput}
                          apiName="autosuggestdata"
                          selections={getValue('alliance_member') === '' ? '' : getValue('alliance_member')}
                          setValuee={(val, key, alliance_type) => {
                            setCenter({
                              val, key, alliance_type,
                            })
                          }}
                          cancelAction={getautosuggestionCancelAction}
                          resetAction={getautosuggestionResetAction}
                          skey="site"
                          defaultValue={getValue('alliance_member') === '' ? '' : getValue('alliance_member')}
                          value={getValue('alliance_member') === '' ? '' : getValue('alliance_member')}
                          checkFlag={data.flag}
                          parentFlag
                        />

                      </Form.Item>
                    </div>

                  ) : (
                    <div className="name-card" disabled>
                      <Form.Item
                        disabled
                        label="Parent Institution"
                      // label="Alliance Member Name"
                        name="alliance_member_id"
                        className="name-wrapper primary-center-name"
                      >
                        <Search
                          disabled
                          allowClear
                          enterButton={(
                            <Button>
                              <span className="search-icon-btn-primary" />
                            </Button>
          )}
                          className="input-wrapper"
                          size="large"
                          maxLength="256"
                          placeholder="Type to search"
                          style={{ width: 400 }}
                        />
                        {/* <PiAutoSelect
                        apiCall={onCenterChangeInput}
                        apiName="autosuggestdata"
                        selections={getValue('alliance_member')}
                        setValuee={(val, key, alliance_type) => {
                          setCenter({ val, key, alliance_type })
                        }}
                        cancelAction={getautosuggestionCancelAction}
                        resetAction={getautosuggestionResetAction}
                        skey="site"
                        defaultValue={getValue('alliance_member')}
                      /> */}
                        {/* <PiAutoSelectForParentInstitution disabled /> */}
                        {/* apiCall={onCenterChangeInput}
                          apiName="autosuggestdata"
                          selections={getValue('alliance_member')}
                          setValuee={(val, key, alliance_type) => {
                            setCenter({
                              val, key, alliance_type,
                            })
                          }}
                          cancelAction={getautosuggestionCancelAction}
                          resetAction={getautosuggestionResetAction}
                          skey="site"
                        // IsDropDownOpen={data.IsDropDownOpen}
                        // SetDropDownOpen={data.SetDropDownOpen}
                          defaultValue={getValue('alliance_member')}
                          value={getValue('alliance_member')}
                          checkFlag={data.flag}
                          parentFlag
                        /> */}

                      </Form.Item>
                    </div>
                  )
                }

                {
                  (data.flag === 'editCenter' ? !data?.generalInformation?.solo_center_flag?.value : !data?.generalInformation?.solo_center_flag) ? (
                    <div className="email-card">
                      <Form.Item
                        label="Is Alliance ?"
                        className="email-wrapper "
                      >
                        <Radio.Group
                          value={(data.flag === 'editCenter' && data?.generalInformation?.solo_center_flag?.value) ? undefined : ((data.flag === 'fromMainCenter' || data.flag === 'savedAnalysis') && data?.generalInformation?.solo_center_flag) ? undefined : getValue('alliance_type')}
                          defaultValue={(data.flag === 'editCenter' && data?.generalInformation?.solo_center_flag?.value) ? undefined : ((data.flag === 'fromMainCenter' || data.flag === 'savedAnalysis') && data?.generalInformation?.solo_center_flag) ? undefined : getValue('alliance_type')}
                          onChange={(e) => onCenterStateChange(e.target.value, 'alliance_type', data.flag)}
                          // disabled={isAllianceDisabled()}
                        >
                          <Radio value="AM">Yes</Radio>
                          <Radio value="">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>

                  ) : (
                    <div className="email-card" disabled>
                      <Form.Item
                        label="Is Alliance ?"
                        className="email-wrapper "
                      >
                        <Radio.Group
                          value={(data.flag === 'editCenter' && data?.generalInformation?.solo_center_flag?.value) ? undefined : ((data.flag === 'fromMainCenter' || data.flag === 'savedAnalysis') && data?.generalInformation?.solo_center_flag) ? undefined : getValue('alliance_type')}
                          defaultValue={(data.flag === 'editCenter' && data?.generalInformation?.solo_center_flag?.value) ? undefined : ((data.flag === 'fromMainCenter' || data.flag === 'savedAnalysis') && data?.generalInformation?.solo_center_flag) ? undefined : getValue('alliance_type')}
                          disabled
                        >
                          <Radio value="AM">Yes</Radio>
                          <Radio value="">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  )
                }

              </div>
            </div>
          </Form>
        )}
        <div className="main-head-wrap">Location</div>
        <Form layout="vertical" name="generalform2" form={data.generalInformationForm} className={data.flag === 'admin' ? 'disable-pi-form' : null}>
          <div className="head-wrapper">
            <div className="inner-card">
              <div className="country-card">
                <Form.Item
                  label="Country"
                  className="country-wrapper"
                  name="country"
                  rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                >
                  <Select
                    showSearch
                    placeholder="Select country"
                    style={{
                      width: 120,
                    }}
                    value={getValue('country')}
                    defaultValue={getValue('country')}
                    onChange={(e) => onCenterStateChange(e, 'country')}
                  >
                    {countryData && countryData?.map((itm) => {
                      return <Option value={itm}>{itm}</Option>
                    })}
                  </Select>
                </Form.Item>
              </div>

              {
                getStateData?.data?.states?.length
                  && getStateData?.data?.states !== undefined
                  ? (
                    <div className="state-card">
                      <Form.Item
                        label="State"
                        rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                        className="state-wrapper required"
                      >

                        <Select
                          showSearch
                          placeholder="Select state"
                          style={{
                            width: 120,
                          }}
                          defaultValue={getValue('state')}
                          value={getValue('state')}
                          onChange={(e) => onStateChange(e, 'state', 'api')}
                        >
                          {getStateData.flag && getStateData?.data?.states?.map((itm) => {
                            return <Option key={itm} value={itm}>{itm}</Option>
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  ) : (
                    <div className="state-card">
                      <Form.Item
                        label="State"
                        className="state-wrapper"
                      >

                        <Input
                          className="input-wrapper"
                          placeholder="Enter state"
                          value={getValue('state')}
                          onChange={(e) => onStateChange(e, 'state', 'text')}
                        />

                      </Form.Item>
                    </div>
                  )
              }

              <div className="city-card">
                <Form.Item
                  label="City"
                  name="city"
                  rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                  className="city-wrapper"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter city"
                    value={getValue('city')}
                    defaultValue={getValue('city')}
                    onChange={(e) => onCenterStateChange(e, 'city')}
                  />
                </Form.Item>
              </div>

              <div className="name-card">
                <Form.Item
                  label="Address line 1"
                  name="addressLineOne"
                  rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                  className="name-wrapper"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter address line 1"
                    value={getValue('address_line1')}
                    defaultValue={getValue('address_line1')}
                    onChange={(e) => onCenterStateChange(e, 'address_line1')}
                  />
                </Form.Item>
              </div>

              <div className="name-card">
                <Form.Item
                  label="Address line 2"
                  name="addressLineTwo"
                  className="name-wrapper"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter address line 2"
                    value={getValue('address_line2')}
                    defaultValue={getValue('address_line2')}
                    onChange={(e) => onCenterStateChange(e, 'address_line2')}
                  />
                </Form.Item>
              </div>

              <div className="postal-card">
                <Form.Item
                  label="Postal Code"
                  className="postal-wrapper"
                  name="postalcode"
                  rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Enter postal code"
                    value={getValue('postalcode')}
                    defaultValue={getValue('postalcode')}
                    onChange={(e) => onCenterStateChange(e, 'postalcode')}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>

        {data.flag === 'editCenter' && hasPermissionForAction('edit_alliance_center') && parse(location.search).currentTab === 'parent_centers'
          && (
            <div>
              <div className="main-head-wrap">Alliance</div>
              <Form layout="vertical" name="generalform2" form={data.generalInformationForm} className={data.flag === 'admin' ? 'disable-pi-form' : null}>
                <div className="head-wrapper">
                  <div className="inner-card">
                    <div className="email-card">
                      <Form.Item
                        label="Is Alliance ?"
                        name="alliance_type"
                        className="email-wrapper"
                        style={{ display: 'flex', flexDirection: 'row', paddingBottom: '30px' }}
                      >
                        <Radio.Group
                          defaultValue={getValue('alliance_type')}
                          value={getValue('alliance_type')}
                          onChange={(e) => onCenterStateChange(e.target.value, 'alliance_type', data.flag)}
                        >
                          <Radio
                            value="AM"
                            onClick={() => {
                              setNoAllianceChildCenters([]);
                              setChildCenterOpenModal(true)
                            }}
                          >
                            Yes
                          </Radio>
                          <Radio value="">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <div className="email-card">
                      <Form.Item
                        label={parse(location.search).currentTab !== 'parent_centers' ? 'Alliance Member' : 'Alliance Member Name'}
                        // label="Alliance Member Name"
                        name="alliance member"
                        className="email-wrapper "
                      >
                        {parse(location.search).currentTab !== 'parent_centers' ? (

                          <PiAutoSelect
                            apiCall={onCenterChangeInput}
                            apiName="autosuggestdata"
                            selections={getValue('alliance_member')}
                            setValuee={(val, key) => { setCenter({ val, key }) }}
                            cancelAction={getautosuggestionCancelAction}
                            resetAction={getautosuggestionResetAction}
                            skey="site"
                            defaultValue={getValue('alliance_member')}
                          />
                        )
                          : (
                            <Input
                              className="input-wrapper"
                              placeholder="Enter alliance member name"
                              value={getValue('alliance_member_name')}
                              defaultValue={getValue('alliance_member_name')}
                              onChange={(e) => onCenterStateChange(e, 'alliance_member_name', data.flag)}
                            />
                          )}
                      </Form.Item>
                    </div>
                    {parse(location.search).currentTab === 'parent_centers' && (
                      <>
                        {getValue('alliance_type') !== '' ? (
                          <div className="email-card">
                            <Form.Item
                              className="postal-wrapper"
                            >
                              <Button type="primary" style={{ left: '35px', top: '35px', minHeight: '10px' }} onClick={() => setChildCenterOpenModal(true)}> Manage Child Centers</Button>
                            </Form.Item>
                          </div>
                        ) : (
                          ''
                          // <div className="email-card">
                          //   <Form.Item
                          //     label="Interest in Site Alliance program"
                          //     className="postal-wrapper"
                          //     name="site_alliance_prog"
                          //   >
                          //     <Radio.Group defaultValue={getValue('interest_in_alliance_program')} value={getValue('interest_in_alliance_program')} onChange={(e) => onCenterStateChange(e, 'interest_in_alliance_program', data.flag)}>
                          //       <Radio value="Yes">Yes</Radio>
                          //       <Radio value="No">No</Radio>
                          //     </Radio.Group>
                          //   </Form.Item>
                          // </div>
                        )}
                        <div className="email-card">
                          <Form.Item
                            label="Interest in Site Alliance program"
                            className="postal-wrapper"
                            name="site_alliance_prog"
                          >
                            <Radio.Group defaultValue={getValue('interest_in_alliance_program')} value={getValue('interest_in_alliance_program')} onChange={(e) => onCenterStateChange(e, 'interest_in_alliance_program', data.flag)}>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <div className="email-card">
                          <Form.Item
                            label="Parexel recommendation for partnering"
                            className="postal-wrapper"
                            name="recommend_partner"
                          >
                            <Radio.Group defaultValue={getValue('is_pxl_recomd_partnering')} value={getValue('is_pxl_recomd_partnering')} onChange={(e) => onCenterStateChange(e, 'is_pxl_recomd_partnering', data.flag)}>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <div className="email-card">
                          <Form.Item
                            label="Who is recommending for partnering"
                            name="alliance recomd_by_for_partnering"
                            className="email-wrapper "
                          >
                            <Input
                              className="input-wrapper"
                              placeholder="Enter name"
                              value={getValue('recomd_by_for_partnering')}
                              defaultValue={getValue('recomd_by_for_partnering')}
                              onChange={(e) => onCenterStateChange(e, 'recomd_by_for_partnering', data.flag)}
                            />
                          </Form.Item>
                        </div>
                        <div className="email-card">
                          <Form.Item
                            label="Recommendation comments"
                            name="recommend comments"
                            className="email-wrapper "
                          >
                            <Input
                              className="input-wrapper"
                              placeholder="Enter comments"
                              value={getValue('recommendation_comments')}
                              defaultValue={getValue('recommendation_comments')}
                              onChange={(e) => onCenterStateChange(e, 'recommendation_comments', data.flag)}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          )}
        {
          data.flag === 'fromMainCenter' && parse(location.search).currentTab === 'parent_centers'
          && (
            <div>
              <div className="main-head-wrap">Alliance</div>
              <Form layout="vertical" name="generalform2" form={data.generalInformationForm} className={data.flag === 'admin' ? 'disable-pi-form' : null}>
                <div className="head-wrapper">
                  <div className="inner-card">
                    <div className="email-card">
                      {/* <Form.Item
                        label="Alliance Type"
                        name="alliance_type"
                        className="email-wrapper "
                      >
                        <Select
                          showSearch
                          placeholder="Select alliance type"
                          style={{
                            width: 120,
                          }}
                          defaultValue={getValue('alliance_type')}
                          value={getValue('alliance_type')}
                          onChange={(e) => onCenterStateChange(e, 'alliance_type')}
                        >
                          {allianceType && allianceType.map((itm) => {
                            return <Option key={itm.value} value={itm.value}>{itm.key}</Option>
                          })}
                        </Select>
                      </Form.Item> */}
                      <Form.Item
                        label="Is Alliance ?"
                        // label="Alliance Member Name"
                        className="email-wrapper "
                      >
                        <Radio.Group value={getValue('alliance_type')} defaultValue={getValue('alliance_type')} onChange={(e) => onCenterStateChange(e.target.value, 'alliance_type', data.flag)}>
                          <Radio value="AM">Yes</Radio>
                          <Radio value="">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>

                    <div className="email-card">
                      <Form.Item
                        label={parse(location.search).currentTab !== 'parent_centers' ? 'Alliance Member' : 'Alliance Member Name'}
                        // label="Alliance Member Name"
                        name="alliance member"
                        className="email-wrapper "
                      >
                        {parse(location.search).currentTab !== 'parent_centers' ? (
                          <PiAutoSelect
                            apiCall={onCenterChangeInput}
                            apiName="autosuggestdata"
                            selections={getValue('alliance_member')}
                            setValuee={(val, key) => { setCenter({ val, key }) }}
                            cancelAction={getautosuggestionCancelAction}
                            resetAction={getautosuggestionResetAction}
                            skey="site"
                            IsDropDownOpen={data.IsDropDownOpen}
                            SetDropDownOpen={data.SetDropDownOpen}
                            defaultValue={getValue('alliance_member')}
                          />
                        )
                          : (
                            <Input
                              className="input-wrapper"
                              placeholder="Enter alliance member name"
                              value={getValue('alliance_member_name')}
                              defaultValue={getValue('alliance_member_name')}
                              onChange={(e) => onCenterStateChange(e, 'alliance_member_name', data.flag)}
                            />
                          )}
                      </Form.Item>
                    </div>

                    {parse(location.search).currentTab === 'parent_centers' && (
                      <>
                        <div className="email-card">
                          <Form.Item
                            label="Interest in Site Alliance program"
                            className="postal-wrapper"
                            name="interest in alliance program"
                          >
                            <Radio.Group value={getValue('interest_in_alliance_program')} onChange={(e) => onCenterStateChange(e, 'interest_in_alliance_program', data.flag)}>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <div className="email-card">
                          <Form.Item
                            label="Parexel recommendation for partnering"
                            className="postal-wrapper"
                            name="recommending_partner"
                          >
                            <Radio.Group value={getValue('is_pxl_recomd_partnering')} onChange={(e) => onCenterStateChange(e, 'is_pxl_recomd_partnering', data.flag)}>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <div className="email-card">
                          <Form.Item
                            label="Who is recommending for partnering"
                            name="recommending partner"
                            className="email-wrapper "
                          >
                            <Input
                              className="input-wrapper"
                              placeholder="Enter name"
                              value={getValue('recomd_by_for_partnering')}
                              defaultValue={getValue('recomd_by_for_partnering')}
                              onChange={(e) => onCenterStateChange(e, 'recomd_by_for_partnering', data.flag)}
                            />
                          </Form.Item>
                        </div>
                        <div className="email-card">
                          <Form.Item
                            label="Recommendation comments"
                            name="recommend comments"
                            className="email-wrapper "
                          >
                            <Input
                              className="input-wrapper"
                              placeholder="Enter comments"
                              value={getValue('recommendation_comments')}
                              defaultValue={getValue('recommendation_comments')}
                              onChange={(e) => onCenterStateChange(e, 'recommendation_comments', data.flag)}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          )
        }
        <div>
          <div className="main-head-wrap">IDs</div>
          <Form layout="vertical" className={data.flag === 'admin' ? 'disable-pi-form' : null}>
            {
              data.ids?.map((itm) => (isEdit.isOpen && isEdit.editKey === itm.key ? (
                <div className="head-wrapper">
                  <div className="inner-card">
                    <div className="email-card">
                      <Form.Item
                        label="ID Type"
                        className="email-wrapper "
                      >
                        <Select
                          options={idsList}
                          style={{
                            width: 120,
                          }}
                          onChange={(e) => setEditId({ ...editId, type: e, label: idsList.find((item) => item.value === e).label })}
                          value={editId.type}
                        />
                      </Form.Item>
                    </div>

                    <div className="email-card">
                      <Form.Item
                        label="ID value"
                        className="email-wrapper "
                      >
                        <Input
                          onChange={(e) => setEditId({ ...editId, value: e.target.value })}
                          value={editId.value}
                        />
                      </Form.Item>
                    </div>
                    <div className="email-card inner-meta-btns">
                      <Button
                        onClick={() => onEditSaveClicked()}
                        disabled={editId.type === '' || editId.value === ''}
                        className="save-btn"
                        title="Save"
                      />
                      <Button
                        className="cancel-btn"
                        onClick={() => onEditCancelClick()}
                        title="Cancel"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="head-wrapper">

                  <div className="inner-card">
                    <div className="email-card">
                      <Form.Item
                        label="ID Type"
                        className="email-wrapper "
                      >
                        {itm.label}
                      </Form.Item>
                    </div>

                    <div className="email-card">
                      <Form.Item
                        label="ID value"
                        className="email-wrapper "
                      >
                        {itm.value}
                      </Form.Item>
                    </div>
                    <div className="email-card instituteDeepdiveList?.data?.datainner-meta-btns">
                      <Button
                        className="plus-btn minus-btn"
                        title="Delete"
                        onClick={() => setDeleteIdConfirmationModal({ isOpen: true, obj: itm })}
                      >
                        -
                      </Button>
                      <Button
                        className="edit-btn"
                        title="Edit"
                        onClick={() => handleEditIdClick(itm)}
                      />
                    </div>
                  </div>
                </div>
              )))
            }
            {isEdit.isOpen === false && (
              <>
                <div className="head-wrapper">
                  <div className="inner-card">
                    <div className="email-card">
                      <Form.Item
                        label="ID Type"
                        className="email-wrapper "
                      >
                        <Select
                          options={idsList}
                          style={{
                            width: 120,
                          }}
                          onChange={(e) => {
                            setValidationError(false)
                            setIdExists(false);
                            setCnnExists(false);
                            setNewId({ ...newId, type: e, label: idsList.find((itm) => itm.value === e).label })
                          }}
                          // onChange={(e) => onChangeIds(e)}
                          value={newId.type}
                        />
                      </Form.Item>
                    </div>

                    <div className="email-card">
                      <Form.Item
                        label="ID value"
                        className="email-wrapper "
                      >
                        <Input
                          onChange={(e) => onChangeIds(e)}
                          // onChange={(e) => {
                          //   setIdExists(false);
                          //   setCnnExists(false);
                          //   setNewId({ ...newId, value: e.target.value })
                          // }}
                          value={newId.value}
                        />
                      </Form.Item>
                      {idExists && <div className="error-msg"> This ID already Exists </div>}
                      {cnnExists && <div className="error-msg"> There can be only one CCN Number for each center </div>}
                      {validationError && <div className="error-msg"> No characters allowed or enter valid number</div>}

                    </div>
                    {/* <div className="email-card inner-meta-btns">
                    <Button
                      onClick={() => AddNewIdClicked()}
                      disabled={newId.type === '' || newId.value === ''}
                      className="create-project-add-rank-btn plus-add-in"
                      title="Add"
                    >
                      <div className="plus-btn-icon" />
                    </Button>
                  </div> */}
                    <div className="email-card inner-meta-btns">
                      <Button
                        onClick={() => AddNewIdClicked()}
                        disabled={newId.type === '' || newId.value === '' || validationError}
                        className="save-btn"
                        title="Save"
                      />
                      <Button
                        className="cancel-btn"
                        onClick={() => {
                          setNewId(
                            {
                              key: '',
                              type: '',
                              value: '',
                              label: '',
                            },
                          );
                          setIdExists(false);
                          setCnnExists(false);
                        }}
                        title="Cancel"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {data.flag === 'editCenter' && hasPermissionForAction('edit_sims_id') && data?.generalInformation?.pxl_center_sims_ids?.value.length === 0
              && (
                <div className="head-wrapper">
                  <div className="inner-card">
                    <div className="add-in-content-id">
                      <div className="add-in-content-id-content">
                        <div className="main-head-wrap" style={{ minWidth: '100%' }}>SIMS IDs</div>
                        <div className="sims-id-card add-in-content-id-content-id-card">
                          <Form.Item
                            className="sims-id-wrapper add-in-content-id-content-id-card-wrapper"
                          >
                            <Input
                              onChange={(e) => { handleSimsIdChange(e.target.value.trim()) }}
                              value={data.simsId}
                              placeholder="Enter SIMS Id"
                            />
                          </Form.Item>
                          <div className="simsid-button add-in-content-id-content-id-card-button inner-meta-btns">
                            {isSimsIdPresent.loading
                              ? <Spin />
                              : (
                                <Button
                                  className="save-btn"
                                  disabled={data.simsId === ''}
                                  onClick={() => dispatch(simsIdPresentAction({
                                    type: 'centers',
                                    simsId: data.simsId,
                                  }))}
                                />
                              )}
                            <Button
                              className="cancel-btn"
                              disabled={data.simsId === ''}
                              onClick={() => {
                                data.setSimsId('')
                                setSimsIdAvailable(false)
                                data.setIsSimsIdVerified(true)
                                setNaNError(false)
                                setSimsIdError(false)
                              }}
                            >
                              <span>-</span>
                            </Button>
                          </div>
                        </div>
                        {simsIdError && <div className="sims-id-error add-in-content-id-content-error">SIMS ID entered is already assigned to another profile</div>}
                        {naNError && <div className="sims-id-error add-in-content-id-content-error">SIMS ID should have only numbers</div>}
                        {simsIdAvailable && <div className="sims-id-error add-in-content-id-content-no-error">SIMS ID Available</div>}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {data.flag === 'editCenter'
              && (
                <div className="add-in-content-id">
                  <div className="add-in-content-id-content">
                    <div className="main-head-wrap" style={{ minWidth: '100%' }}>Veeva Ready Flag</div>
                    <div className="sims-id-card add-in-content-id-content-id-card">
                      {
                      ((getCookie('permissions').includes('super_admin') || getCookie('permissions').includes('lcor_access')) && checkedMandatoryFields() && getValue('veeva_ready_flag_updated') !== 'Yes' && getValue('veeva_ready_flag_updated') !== '')
                        ? (
                          <Form.Item
                            style={{ paddingLeft: '15px' }}
                            className="postal-wrapper"
                          >
                            <Radio.Group
                              value={veevaReadyValue}
                              onChange={(v) => onRadioValueChange(v)}
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="Pending review">Pending Review</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>

                        ) : (
                          <Form.Item
                            style={{ paddingLeft: '15px' }}
                            className="postal-wrapper"
                            // className="sims-id-wrapper add-in-content-id-content-id-card-wrapper"
                          >
                            <Radio.Group value={getValue('veeva_ready_flag_updated')} disabled>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="Pending review">Pending Review</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        )
                    }
                    </div>
                  </div>
                </div>
              )}
          </Form>
        </div>
        {
          deleteIdConfirmationModal.isOpen && (
            <Modal
              title="Delete ID"
              visible={deleteIdConfirmationModal.isOpen}
              onOk={() => handleDeleteIdClick(deleteIdConfirmationModal.obj)}
              onCancel={() => setDeleteIdConfirmationModal({ isOpen: false, obj: {} })}
              okText="Yes"
              cancelText="No"
            >
              Do you really want to delete this ID?
            </Modal>
          )
        }
        {

          <Modal
            className="change-preferred-site-modal manange-alliance-network-modal"
            visible={childCenterOpenModal}
            onCancel={() => setChildCenterOpenModal(false)}
            okText="Done"
            onOk={() => handleAllianceModalDoneClicked()}
            width={1000}
            cancelButtonProps={{ style: { display: 'none' } }}
            title="Manange Alliance Network"
          >
            <Loader loading={childCenterData.loading} error={childCenterData.error}>
              {childCentersList.length
                ? (
                  <>
                    <div className="preferred-site-alliance-header">
                      <div className="preferred-site-alliance-title">
                        Child Centers
                      </div>
                      <div className="preferred-site-alliance-title-end">
                        Is Alliance ?
                      </div>
                    </div>
                    <div className="orglist-main">
                      <div className="preferred-site-alliance sec-bottom-border">
                        {childCentersList.map((site) => (
                          // <div className="orglist-card">
                          //   <div className="orgname">
                          //     <div className="orgname-title">
                          //       Name:
                          //     </div>
                          //     <div className="orgname-value">
                          //       {site.name}
                          //     </div>
                          //   </div>
                          //   <div className="address">
                          //     <div className="adress-title">
                          //       Address:
                          //     </div>
                          //     <div className="address-value">
                          //       {site.address ? (
                          //         <div className="content-child">
                          //           {site?.address?.address_line1 && `${site?.address?.address_line1}, `}
                          //           {site?.address?.address_line2 && `${site?.address?.address_line2}, `}
                          //           {site?.address?.city && `${site?.address?.city}, `}
                          //           {site?.address?.state && `${site?.address?.state}, `}
                          //           {site?.address?.country && `${site?.address?.country}, `}
                          //           {site?.address?.zip}
                          //         </div>
                          //       ) : (
                          //         <div className="content-child no-data">No data available</div>
                          //       )}
                          //     </div>
                          //   </div>
                          //   <div className="simid">
                          //     <div className="simid-title">
                          //       SIMS Id:
                          //     </div>
                          //     <div className="simid-value">
                          //       {site?.simsid}
                          //     </div>
                          //   </div>
                          //   <div className="landscapeid">
                          //     <div className="landscapeid-title">
                          //       LandSCAPE Id:
                          //     </div>
                          //     <div className="landscapeid-value">
                          //       {site?.landscape_id ? site?.landscape_id : ''}
                          //     </div>
                          //   </div>
                          //   <div className="org-select-btn">
                          //     <Checkbox checked={!noAllianceChildCenters.includes(site.id)} style={{ paddingRight: '20px' }} onClick={() => handleCheckboxClick(site.id)} />
                          //   </div>
                          // </div>
                          <>
                            <div className="manage-alliance-net-card">
                              <div
                                className={`card  ${site.type} ${site.parent_flag === true ? 'flag-active' : 'no-flag'} ${site.is_invalid === true ? 'invalid-itm-card' : ''}`}
                              >
                                <div className="card-header">
                                  <div className="card-title">
                                    <span className="flag-icon-img" />
                                    {site.name}
                                  </div>
                                </div>
                                <div className="content">
                                  <div className="leftside">
                                    <div className="person-details">
                                      <div className="contact_name">
                                        <div className="title-child">Address:</div>
                                        {site.address
                                          ? (
                                            <div className="content-child">{site?.address?.address_line1 && `${site?.address?.address_line1}, `}
                                              {site?.address?.address_line2 && `${site?.address?.address_line2}, `}
                                              {site?.address?.city && `${site?.address?.city}, `}
                                              {site?.address?.state && `${site?.address?.state}, `}
                                              {site?.address?.country && `${site?.address?.country}, `}
                                              {site?.address?.zip}
                                            </div>
                                          )
                                          : (
                                            <div className="content-child no-data">No data available</div>
                                          )}
                                      </div>
                                      <div className="emailofcontact">
                                        <div className="title-child">Email:</div>
                                        {site.email && site.email.length
                                          ? (
                                            <>
                                              <div className="content-child">
                                                {site.email.join(', ')}
                                              </div>
                                            </>
                                          )
                                          : (
                                            <div className="content-child no-data">No data available</div>
                                          )}
                                      </div>
                                      <div className="mobileOfcontact" style={{ overflow: 'hidden' }}>
                                        <div className="title-child">Phone:</div>
                                        {site.phone_number && site.phone_number.length
                                          ? (
                                            <div className="content-child">{site.phone_number.join(', ')}</div>
                                          )
                                          : (
                                            <div className="content-child no-data">No data available</div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-side">
                                    <div className="tags-data">
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">{tagTitle.tier}</div>
                                          <div className="sizeOftag">{site.tier}</div>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">{tagTitle.alliance}</div>
                                          <div className="sizeOftag">{site.alliance_type ? site.alliance_type : '-'}</div>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">{tagTitle.compliance}</div>
                                          <div className="sizeOftag">{site.compliance ? 'Yes' : 'No'}</div>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">{tagTitle.total_number_of_popis}</div>
                                          <div className="sizeOftag">{site.total_number_of_popis}</div>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">{tagTitle.industry_studies}</div>
                                          <div className="sizeOftag">{site.industry_studies}</div>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">{tagTitle.interventional_studies}</div>
                                          <div className="sizeOftag">{site.interventional_studies}</div>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">{tagTitle.observational_studies}</div>
                                          <div className="sizeOftag">{site.observational_studies}</div>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">{tagTitle.parexel_studies}</div>
                                          <div className="sizeOftag">{site.parexel_studies}</div>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">{tagTitle.indications}</div>
                                          <Tooltip
                                            placement="bottom"
                                            title={
                                              site.indications && site.indications.length
                                                ? site.indications.join('; ')
                                                : '-'
                                            }
                                          >
                                            <span className="sizeOftag">
                                              {site.indications && site.indications.length
                                                ? site.indications.join('; ')
                                                : '-'}
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">Sources&nbsp;</div>
                                          <Tooltip
                                            placement="bottom"
                                            title={
                                              site.sources && site.sources.length
                                                ? site.sources.join(', ')
                                                : '-'
                                            }
                                          >
                                            <span className="sizeOftag">
                                              {site.sources && site.sources.length
                                                ? site.sources.join(', ')
                                                : '-'}
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </Tag>
                                      <Tag>
                                        <div className="details-abt-tags">
                                          <div className="nameOfTag">
                                            {parse(location.search).currentTab === 'centers'
                                              ? 'Center Type' : 'Parent Institution Type'}
                                          </div>
                                          <Tooltip
                                            placement="bottom"
                                            title={
                                              site.center_type
                                                ? site.center_type
                                                : '-'
                                            }
                                          >
                                            <span className="sizeOftag">
                                              {site.center_type
                                                ? site.center_type
                                                : '-'}
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </Tag>
                                      {site.pxl_center_sims_ids
                                        ? (
                                          <Tag>
                                            <div className="details-abt-tags">
                                              <div className="nameOfTag">SIMS IDs&nbsp;</div>
                                              <Tooltip
                                                placement="bottom"
                                                title={
                                                  site.pxl_center_sims_ids && site.pxl_center_sims_ids.length
                                                    ? site.pxl_center_sims_ids.join(', ')
                                                    : '-'
                                                }
                                              >
                                                <span className="sizeOftag">
                                                  {site.pxl_center_sims_ids && site.pxl_center_sims_ids.length ? site.pxl_center_sims_ids.join(', ') : '-'}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          </Tag>
                                        )
                                        : null}
                                      {site.pxl_center_cit_ids
                                        ? (
                                          <Tag>
                                            <div className="details-abt-tags">
                                              <div className="nameOfTag">Citeline IDs&nbsp;</div>
                                              <Tooltip
                                                placement="bottom"
                                                title={
                                                  site.pxl_center_cit_ids && site.pxl_center_cit_ids.length
                                                    ? site.pxl_center_cit_ids.join(', ')
                                                    : '-'
                                                }
                                              >
                                                <span className="sizeOftag">
                                                  {site.pxl_center_cit_ids && site.pxl_center_cit_ids.length ? site.pxl_center_cit_ids.join(', ') : '-'}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          </Tag>
                                        )
                                        : null}

                                      {site.landscape_id
                                        ? (
                                          <Tag>
                                            <div className="details-abt-tags">
                                              <div className="nameOfTag">LandSCAPE ID&nbsp;</div>
                                              <Tooltip
                                                placement="bottom"
                                                title={
                                                  site.landscape_id ? site.landscape_id
                                                    : '-'
                                                }
                                              >
                                                <span className="sizeOftag">
                                                  {site.landscape_id ? site.landscape_id : '-'}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          </Tag>
                                        )
                                        : null}
                                    </div>
                                    <div className="reliability-quality-rating">
                                      <Tooltip title={site.reliability ? `Reliability score : ${site.reliability}` : '-'}>
                                        <div className="reliability rating-sec">
                                          <div className="rating-sec-title">Reliability</div>

                                          {site.reliability_class && site.reliability_class !== ''
                                            ? (
                                              <StarRatings
                                                rating={getStars(site.reliability_class)}
                                                starRatedColor="#8A0050"
                                                numberOfStars={3}
                                                starDimension="16px"
                                                starSpacing="1px"
                                                starEmptyColor="#D099B9"
                                              />
                                            )
                                            : (
                                              <div className="content-child no-data">&nbsp;No data available</div>
                                            )}
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-meta">
                                <div className={noAllianceChildCenters.includes(site.id) ? 'checked-failure' : 'checked-success'} role="presentation" onClick={() => handleCheckboxClick(site.id)} />
                                {/* <Checkbox checked={noAllianceChildCenters.includes(site.id) === false} style={{ paddingLeft: '40px' }} onClick={() => handleCheckboxClick(site.id)} /> */}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="no-data-found-msg">No child centers found</div>
                )}
            </Loader>
          </Modal>
        }
        {
          veevaReadyWarningModal ? (
            <Modal
              title="Confirmation"
              open={veevaReadyWarningModal}
              onOk={() => submitVeevaReadyValue()}
              onCancel={() => {
                setveevaReadyValue('Pending review')
                setVeevaReadyWarningModal(false)
              }}
              cancelButtonProps={{ style: { border: '1px solid #8A0050' } }}
            >
              {parse(location.search).currentTab !== 'parent_centers' ? 'Are you sure you want to mark this Center as Veeva Ready?' : 'Are you sure you want to mark this Parent Institution; as Veeva Ready?'}
            </Modal>
          ) : null
        }

        {
         veevaReadyNoValueWarningModal ? (
           <Modal
             title="Warning"
             open={veevaReadyNoValueWarningModal}
             onCancel={() => {
               setveevaReadyValue(data?.generalInformation?.veeva_ready_flag_updated?.value)
               setVeevaReadyWarningModal(false)
               setVeevaReadyNoValueWarningModal(false)
             }}
             okButtonProps={{ style: { display: 'none' } }}
             cancelButtonProps={{ style: { border: '1px solid #8A0050' } }}
           >
             {parse(location.search).currentTab !== 'parent_centers' ? "For a Center that is Pending Review, the Veeva Ready flag cannot be set to 'No'" : "For a Parent Institution that is Pending Review, the Veeva Ready flag cannot be set to 'No'"}
           </Modal>
         ) : null
        }
      </div>
    </div>
  );
};

export default GeneralInformation;
